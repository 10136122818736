import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <div
      className="background-section"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
      }}
    ></div>
    <div
          className="section-hero"
      >
        <h1
          className="teaser-text"
        >
          {title}
        </h1>
    </div>
    <section className="section section--gradient teaser-answer">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-12">
              <div className="content main-info">
                <div className="content intro">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="full-width-image-container in-between-img" />

    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-12">
              <div className="content main-info">
                <div className="columns">
                  <div className="column is-12 agau-presentation">
 <p> Die zurückliegenden Jahre haben gezeigt, dass internationale Währungssystem immer mehr auf tönernden Füßen steht – die Finanzkrise ist längst nicht überstanden. Immer mehr Menschen verlieren das Vertrauen in das herrschende Papiergeldsystem und stellen sich die Frage:</p>
                    <h3 className="headline">
                    Wie sicher sind meine Ersparnisse und Anlagen?
                    </h3>
                    <p>
                      Hinzu kommt, dass die ständig anwachsende Inflation den Wert des Papiergeldes unaufhörlich sinken lässt.
                    </p>
                  </div>
                </div>
                <div className="column is-12">
                  <h3 className="blogroll-title headline">
                  Handeln Sie, bevor es zu spät ist
                  </h3>
                  <div
                  className="full-width-image-container"
                  style={{
                    backgroundImage: `url('../img/agau-10-australian-gold-silver.jpg')`,
                    backgroundSize: `cover`,
                    backgroundRepeat: `no-repeat`
                  }}>

                  </div>
                  <p style={{
                    marginTop: `5rem`,
                    marginBottom: `2rem`,
                  }}>
    und tauschen Sie einen Teil Ihres Papiergeldes
    in international anerkannte Anlagemünzen aus Gold und Silber
    sowie Barren renommierter Prägestätten.
    Edelmetalle sind seit Jahrtausenden ein idealer Wertspeicher
    und gehören zu den ältesten und stabilsten Zahlungsmitteln der Welt.
    Allen anderen Alternativen vorzuziehen ist der anonyme Besitz
    von physisch vorhandenen Edelmetallen.
    Profitieren Sie von unserem Tafelgeschäft, bei dem es keinen „Roten Faden" gibt.
                  </p>
                  <div className="blog-read-more-articles column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                    Das Zeitalter der Sachwerte hat begonnen <span style={{position: `relative`, top: `2px`}}>➡</span>
                    </Link>
                  </div>
                  <div
                  className="full-width-image-container"
                  style={{
                    backgroundImage: `url('../img/agau-8-jena.jpg')`,
                    backgroundSize: `cover`,
                    backgroundRepeat: `no-repeat`,
                    marginBottom: `-3.5rem`,
                  }}>

                  </div>
                </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
